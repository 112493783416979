import React from "react"
import styles from "./discountbanner.module.scss"

const Discount = () => (
  <div className={styles.sale}>
    <div className="container">
      <div className={styles.wrap}>
        <div className={styles.text}>
          <div className={styles.textwrapper}>
            <div className={styles.slstr}>
              <img src={require("../../../images/knowledgebase/fire.png")} alt={"Распродажа"}/>
              <span>Распродажа</span>
            </div>
            &nbsp;выгодных подписок на Параплан CRM
          </div>
          </div>
        <a className={styles.discbutton} href="/blog/akciya-dlya-klientov-paraplan">Подробнее</a>
      </div>
    </div>
  </div>
)

export default Discount
